import numeral from 'numeral';

export function formatDollars (value: number) {
  if (value >= 0) {
    return '$' + numeral(value).format('9,999');
  }
  return '($' + numeral(Math.abs(value)).format('9,999') + ')';
}

export function formatRate (value: number) {
  if (value !== null) {
    return numeral(value).format('9.000');
  }
}

export function formatPoints (value: number) {
  if (value !== null) {
    return numeral(value).format('9.000');
  }
}

export function formatLongDate (value: string) {
  if (value) {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
}
